<template>
  <v-row class="text-center px-3 pt-3">
    <center v-if="question.img">
      <img :src="$http.defaults.backDir+'/'+question.img" style="border: 1px solid; max-height: 400px" />
    </center>
    <draggable
      v-bind="dragOptions"
      v-model="question.variants"
      @start="dragStart($event)"
      @end="dragEnd()"
      style="width: 100%"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <v-col
          cols="12"
          class="py-2"
          v-for="(variant, index) in question.variants"
          :key="variant.id"
          style="cursor: pointer"
        >
          <v-card
            elevation="2"
            class="pa-3"
            shaped
            v-bind:class="drag && dragIndex != index ? 'grey' : ''"
          >
            <v-chip class="float-left mt-0" small>{{ index + 1 }}</v-chip>
            {{ variant.text }}
          </v-card>
        </v-col>
      </transition-group>
    </draggable>
  </v-row>
</template>
<style >
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
<script>
import draggable from "vuedraggable";
export default {
  name: "order",
  props: ["question"],
  components: {
    draggable,
  },
  data: () => ({
    drag: false,
    dragIndex: null,
    order: [],
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    "question.id"() {
      this.updateState();
    },
  },
  methods: {
    updateState() {
      if (this.question.answer) {
        this.question.variants = this.question.answer.map((object) =>
          this.question.variants.find((el) => el.id == object)
        );
      }
    },
    dragEnd() {
      let order = this.question.variants.map((el) => el.id);
      this.$set(this.question, "answer", order);
      this.$set(this.question, "filled", true);
      this.$emit("updateAnswer");
      this.drag = false;
    },
    dragStart(e) {
      this.dragIndex = e.oldDraggableIndex;
      this.drag = true;
      console.log(e);
    },
  },
  mounted() {
    this.updateState();
    this.dragEnd()
  },
};
</script>