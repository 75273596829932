<template>
  <div>
    <v-btn
      v-if="!hideBtn"
      v-observe-visibility="visibilityChanged"
      :fab="float"
      id="helper"
      @click="tourStart()"
      :style="
        float ? 'position: absolute; bottom:25px; left:25px; z-index:10' : ''
      "
      ><v-icon large>mdi-head-question-outline</v-icon></v-btn
    >
    <v-tour
      :name="TourName"
      ref="tour"
      :steps="steps"
      :options="tourOptions"
      :callbacks="tourCallbacks"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template
              v-if="
                tour.currentStep > 0 && tour.currentStep < tour.steps.length - 1
              "
            >
              <div slot="content">
                <div class="text-right ma-1">
                  <v-btn
                    v-if="$store.state.soundOn"
                    small
                    @click="switchSound()"
                    color="green"
                    dark
                    ><v-icon>mdi-volume-high</v-icon></v-btn
                  >
                  <v-btn v-else small @click="switchSound()" color="grey" dark
                    ><v-icon>mdi-volume-off</v-icon></v-btn
                  >
                  <v-btn
                    @click="tour.skip"
                    small
                    class="mx-1"
                    dark
                    color="red"
                    style="z-index: 1"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </div>
                <v-sheet class="mx-2 mt-3 pa-1" rounded>
                  {{ tour.steps[tour.currentStep].content }}
                </v-sheet>
              </div>
              <div slot="actions">
                <div class="d-flex justify-space-between my-2 mx-1">
                  <v-btn @click="tour.previousStep" small class="mx-1"
                    >Назад</v-btn
                  >
                  <v-btn
                    @click="tour.nextStep"
                    small
                    class="mx-1"
                    color="primary"
                    >Далее</v-btn
                  >
                </div>
              </div>
            </template>
            <template v-if="tour.currentStep <= 0 ">
                          <div slot="content">
                <div class="text-right ma-1">
                  <v-btn
                    v-if="$store.state.soundOn"
                    small
                    @click="switchSound()"
                    color="green"
                    dark
                    ><v-icon>mdi-volume-high</v-icon></v-btn
                  >
                  <v-btn v-else small @click="switchSound()" color="grey" dark
                    ><v-icon>mdi-volume-off</v-icon></v-btn
                  >
                  <v-btn
                    @click="tour.skip"
                    small
                    class="mx-1"
                    dark
                    color="red"
                    style="z-index: 1"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </div>
                <v-sheet class="mx-2 mt-3 pa-1" rounded>
                  {{ tour.steps[tour.currentStep].content }}
                </v-sheet>
              </div>
              <div slot="actions" class="pa-0">
                  <div class="d-flex justify-space-between my-2 mx-1">
                      <div></div>
                <v-btn @click="tour.nextStep" v-if="tour.steps.length>1" small class="mx-1" color="primary"
                  >Далее</v-btn
                >
                </div>
              </div>
            </template>
            <template v-if="tour.currentStep >= tour.steps.length - 1 &&  tour.steps.length>1">
                                      <div slot="content">
                <div class="text-right ma-1">
                  <v-btn
                    v-if="$store.state.soundOn"
                    small
                    @click="switchSound()"
                    color="green"
                    dark
                    ><v-icon>mdi-volume-high</v-icon></v-btn
                  >
                  <v-btn v-else small @click="switchSound()" color="grey" dark
                    ><v-icon>mdi-volume-off</v-icon></v-btn
                  >
                  <v-btn
                    @click="tour.skip"
                    small
                    class="mx-1"
                    dark
                    color="red"
                    style="z-index: 1"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </div>
                <v-sheet class="mx-2 mt-3 pa-1" rounded>
                  {{ tour.steps[tour.currentStep].content }}
                </v-sheet>
              </div>
              <div slot="actions" class="pa-0">
                  <div class="d-flex justify-space-between my-2 mx-1">
                <v-btn @click="tour.previousStep" small class="mx-1"
                  >Назад</v-btn
                >
                <v-btn @click="tour.stop" small class="mx-1" color="green" dark
                  >Завершить обучение</v-btn
                >
                </div>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>
<style>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
  pointer-events: none;
}
.v-step {
  padding: 0px !important;
}
</style>
<script>
export default {
  name: "index",
  props: {
    groupId: Number,
    autostart: Boolean,
    delay: Number,
    closeOnEnd: Boolean,
    float: Boolean,
    hideBtn: Boolean,
    left: Boolean,
    autoPlay: Boolean,
  },
  data() {
    return {
      tourOptions: {
        id: 1,
        highlight: true,
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: "Закончить",
          buttonPrevious: "Назад",
          buttonNext: "Далее",
          buttonStop: "ОК",
        },
      },
      steps: [],
      tourCallbacks: {
        onStop: this.tourStop,
        onNextStep: this.playNextAudio,
        onPreviousStep: this.playPrevAudio,
        onStart: this.playFirstAudio,
        onFinish: this.finish,
      },
      autostarted: false,
      audio: null,
    };
  },
  computed: {
    TourName() {
      return "first" + this.groupId;
    },
  },
  mounted() {
    if (this.autostart == true) {
      if (this.delay) {
        setTimeout(this.getSteps, this.delay);
      } else {
        this.getSteps();
      }

      this.autostarted = true;
    }
  },
  beforeDestroy: function () {
    this.$tours[this.TourName].stop();
  },
  methods: {
    switchSound() {
      this.$store.state.soundOn = !this.$store.state.soundOn;
      if (!this.$store.state.soundOn && this.audio) {
        this.audio.pause();
      }
    },
    visibilityChanged(isVisible, entry) {
      if (
        isVisible == false &&
        entry.boundingClientRect.x == 0 &&
        entry.boundingClientRect.y == 0
      ) {
        this.$tours[this.TourName].stop();
      }
      console.log(entry);
    },
    getSteps() {
      this.$http
        .get("revision/help/get", {
          params: {
              timetableId:this.$route.params.id,
            groupId: this.groupId,
          },
        })
        .then((response) => {
          this.$set(this, "steps", response.data.steps);
          this.$nextTick(() => {
            if (this.autostarted == false || !response.data.done) {
              this.$tours[this.TourName].start();
            }
            this.autostarted = false;
          });
        });
    },
    playFirstAudio(currentStep) {
      if (this.audio) {
        this.audio.pause();
      }
      if (this.$store.state.soundOn) {
        this.audio = new Audio(
          this.$http.defaults.speechHost + "/" + this.steps[0].file
        ); // path to file
        this.audio.play();
        var vm = this;
        this.audio.onended = function () {
          if (currentStep == vm.steps.length) {
            vm.$tours[vm.TourName].stop();
          } else {
            if (vm.autoPlay) {
              vm.$tours[vm.TourName].nextStep();
            }
          }
        };
      }
    },
    playPrevAudio(currentStep) {
      if (this.$store.state.soundOn) {
        this.audio.pause();
        this.audio = new Audio(
          this.$http.defaults.speechHost +
            "/" +
            this.steps[currentStep - 1].file
        ); // path to file
        this.audio.play();
      }
    },
    playNextAudio(currentStep) {
      if (this.$store.state.soundOn) {
        this.audio.pause();
        this.audio = new Audio(
          this.$http.defaults.speechHost +
            "/" +
            this.steps[currentStep + 1].file
        ); // path to file
        this.audio.play();
        var that = this;
        this.audio.onended = function () {
          if (that.$tours[that.TourName].isLast) {
            if (that.closeOnEnd) {
              that.$tours[that.TourName].stop();
            }
          } else {
            if (that.autoPlay) {
              that.$tours[that.TourName].nextStep();
            }
          }
        };
      }
    },
    finish() {},
    tourStop() {
      if (this.$store.state.soundOn) {
        if (this.audio) {
          this.audio.pause();
        }
      }

      this.$http.get("revision/help/finish-help", {
        params: {
            timetableId:this.$route.params.id,
          groupId: this.groupId,
        },
      });

      /*
                 if (!this.$auth.user().helpStory.find(el => el == this.tourOptions.id))
                 {
                 this.$http.get('profile/finish-help',
                 {
                 params: {
                 id: this.tourOptions.id
                 }
                 }).then(response => {
                 this.$auth.user().helpStory.push(response.data)
                 })
                 }
                 * 
                 */
    },
    tourStart() {
      this.getSteps();
    },
  },
};
</script>