<template>
  <div>
    <v-navigation-drawer
      right
      width="600px"
      v-model="drawer"
      absolute
      temporary
      clipped
    >
      <v-list-item>
        <v-list-item-content>
          <v-text-field label="Поиск" v-model="searchString" />
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in filtered"
          :key="item.id"
          link
          @click="pushDoc(item)"
        >
          <v-list-item-content>
            {{ item.name }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="docViewModal" fullscreen>
      <v-card>
        <v-card-actions class="justify-space-between grey lighten-3">
          <h4 v-if="docView[currentDoc]">{{ docView[currentDoc].name }}</h4>
          <v-btn small @click="docViewModal = false" fab color="red" dark
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-actions>
        <v-divider />
        <v-card-text
          v-for="(document, index) in docView"
          :key="document.name"
          class="ma-0 pa-0"
        >
          <embed
            v-show="currentDoc == index"
            :src="$http.defaults.backDir + '/' + document.file"
            style="width: 100%; height: calc(100vh - 90px)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <helper
      v-if="showHelp"
      :groupId="2"
      :autostart="true"
      :closeOnEnd="true"
      :float="true"
      :hideBtn="true"
    />
    <v-btn
      class="docmark"
      :elevation="4"
      v-for="(document, index) in docView"
      :key="index"
      :style="
        'position:fixed; bottom:0px; left:' +
        (20 + 100 * index) +
        'px; z-index:10'
      "
      @click="showDoc(index)"
      id="showDocFast"
      >
        
        <span
        class="text-caption"
          style="
            display:block;
            max-width: 300px;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          <v-icon color="primary" class="mr-2">mdi-bookmark</v-icon> {{ document.name }}
        </span>
        <v-btn icon small @click.stop="docView.splice(index, 1)" color="red"
          ><v-icon>mdi-close</v-icon></v-btn
        >
 
      </v-btn>
  </div>
</template>
<style scoped>
.docmark:hover {
  z-index: 100 !important;
}
</style>
<script>
import helper from "../helper";
export default {
  data: () => ({
    drawer: false,
    docViewModal: false,
    currentDoc: 0,
    docView: [],
    searchString: "",
    items: [],
    showHelp: false,
  }),
  watch: {
    docViewModal(val) {
      if (val === false && this.docView[0].name) {
        this.showHelp = true;
      }
    },
  },
  components: {
    helper,
  },
  computed: {
    filtered() {
      return this.items.filter(
        (el) =>
          el.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1
      );
    },
  },
  methods: {
    showDoc(index) {
      this.currentDoc = index;
      this.docViewModal = true;
    },
    pushDoc(item) {
      let opened=this.docView.findIndex(el=>el.id===item.id)
      if(opened!==-1){
        this.currentDoc=opened
        this.docViewModal = true
        return
      }
      if (this.docView.length > 2) {
        this.docView[2] = item;
      } else {
        this.docView.push(item);
      }
      this.currentDoc = this.docView.length - 1;
      this.docViewModal = true;
    },
    fetchData() {
      this.$http
        .get("revision/theory/acts-get?timetableId="+this.$route.params.id)
        .then((response) => {
          this.items = response.data;
        })
        .catch(() => {
          alert("Ошибка при загрузке документов");
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>