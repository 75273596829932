<template>
  <div class="steps pt-0" fluid>
    <helper :groupId="1" :autostart="true" :closeOnEnd="true" :float="true" />
    <documents ref="documents" />
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="pb-3 pt-0" v-show="!loading">
      <div class="d-flex justify-space-between grey lighten-3 pa-1 mb-0">
        <v-chip> {{ this.$auth.user().name }}</v-chip>
        <div style="width: 300px" class="text-right" id="timer">
          Осталось времени:<v-chip :color="timerColor" dark class="mx-3">
            <v-icon>mdi-timer-outline</v-icon> {{ countdown.hours }}:{{
              countdown.minutes
            }}:{{ countdown.seconds }}</v-chip
          >
        </div>
        <v-btn @click="$auth.logout()">Выход</v-btn>
      </div>
      <v-toolbar flat tile>
        <v-toolbar-title id="navArea"
          ><v-tabs :show-arrows="true" v-model="page"
            ><v-tab
              class="pa-0 ma-0"
              style="min-width: 0px"
              v-for="(pageQuestion, index) in questions"
              :key="pageQuestion.id"
              @change="question = pageQuestion"
              @click="question = pageQuestion"
              ><v-btn
                :loading="pageQuestion.saving"
                elevation="1"
                :outlined="!pageQuestion.saved"
                :color="pageQuestion.saved ? 'blue lighten-2' : 'gray'"
                tile
                >{{ index + 1 }}</v-btn
              ></v-tab
            ></v-tabs
          > </v-toolbar-title
        ><v-spacer />
        <v-btn
          @click="$refs.documents.drawer = true"
          color="primary"
          id="docBtn"
          ><v-icon color="white" class="pr-2">mdi-information-outline</v-icon
          >Литература</v-btn
        >
        <v-btn
          :disabled="!questions.every((el) => el.saved === true)"
          @click="finishModal = true"
          color="green"
          tile
          :dark="questions.every((el) => el.saved === true)"
          >Завершить</v-btn
        >
      </v-toolbar>
      <v-divider></v-divider>
      <div
        v-if="question"
        class="pa-0"
        style="max-height: calc(100vh - 110px); overflow: auto"
      >
        <v-container class="steps">
          <v-card :elevation="4">
            <v-card-text>
              <div style="position: absolute; top: 5px; right: 5px; z-index: 1">
                <v-tooltip left offset-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="44"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      id="questionDesc"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <questionDesc :question="question" />
                </v-tooltip>
              </div>
              <v-card
                elevation="1"
                outlined
                rounded
                class="text-left pa-0"
                id="answerArea"
              >
                <center class="mt-3">
                  Вопрос
                  {{ questions.findIndex((el) => el == question) + 1 }} из
                  {{ questions.length }}
                </center>
                <h2
                  class="ma-3"
                  style="line-height: 22pt"
                  v-if="question.type_id !== 4"
                >
                  {{ question.text }}
                </h2>
              </v-card>
              <simple
                v-if="question.type_id === 1"
                :question="question"
                @updateAnswer="saveAnswer()"
              />
              <order
                v-if="question.type_id === 3"
                :question="question"
                @updateAnswer="saveAnswer()"
              />
              <compliance
                v-if="question.type_id === 2"
                :question="question"
                @updateAnswer="saveAnswer()"
              />
              <openAnswer
                v-if="question.type_id === 4"
                :question="question"
                @updateAnswer="saveAnswer()"
              />
              <center>
                <v-btn
                  @click="nextQuestion()"
                  class="mt-5"
                  color="blue"
                  dark
                  v-if="!questions.every((el) => el.saved === true)"
                  >Следующий вопрос</v-btn
                >
              </center>
            </v-card-text>
          </v-card>
        </v-container>
      </div>
    </div>
    <v-dialog v-model="finishModal" persistent width="400">
      <v-card>
        <v-card-title> Вы уверены? </v-card-title>
        <v-card-text class="pb-0">
          После нажатия кнопки "Завершить" дальнейшее изменение ответов будет
          невозможно
        </v-card-text>
        <v-card-actions class="justify-space-between">
          <v-btn @click="finishModal = false" class="ma-3" :loading="ending"
            >Отмена</v-btn
          >
          <v-btn
            @click="finish()"
            color="primary"
            class="ma-3"
            :loading="ending"
            >Завершить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="helpModal" width="800" persistent>
      <v-card>
        <v-card-title> Новый тип вопроса </v-card-title>
        <v-divider />
        <v-card-text>
          <center>
            <questionDesc :question="question" />
          </center>
          <center>
            <v-btn @click="helpModal = false" color="primary" class="ma-3"
              >Понятно</v-btn
            >
          </center>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
<script>
import simple from "../../components/theory/simple";
import order from "../../components/theory/order";
import compliance from "../../components/theory/compliance";
import openAnswer from "../../components/theory/openAnswer";
import documents from "../../components/theory/documents.vue";
import questionDesc from "../../components/theory/desc.vue";
import helper from "../../components/helper";
export default {
  name: "theory",
  components: {
    simple,
    order,
    compliance,
    openAnswer,
    documents,
    questionDesc,
    helper,
  },
  computed: {
    timerColor() {
      if (this.currentTime < 180) {
        return "red";
      }
      if (this.currentTime < 600) {
        return "yellow";
      }
      return "green";
    },
  },
  watch: {
    currentTime(time) {
      if (time <= 0) {
        this.$nextTick(() => {
          this.stopTimer();
          alert("Время вышло");
          this.finish();
        });
      }
    },
  },
  methods: {
    checkAnswerSaved(question) {
      let answer = question.answer;
      if (question.unsaved) {
        console.log(question.id);
        return false;
      }
      if (question.type_id === 4 && answer !== "") {
        return answer.every((el) => el != "");
      }
      if (Object.prototype.toString.call(answer) === "[object Array]") {
        return answer.findIndex((el) => el != "") === -1 ? false : true;
      } else {
        return answer !== "" ? true : false;
      }
    },
    ping() {
      this.$http
        .get("revision/theory/ping?timetableId=" + this.$route.params.id)
        .then((response) => {
          if (response.data.timer) {
            this.currentTime = response.data.timer;
          }
        });
    },
    nextQuestion() {
      if (!this.questions[this.page + 1]) {
        this.page = 0;
      } else {
        this.page++;
      }
      this.question = this.questions[this.page];
    },
    saveAnswer() {
      let questionIndex = this.questions.findIndex(
        (el) => el.id === this.question.id
      );
      this.questions[questionIndex].saving = true;
      this.$http
        .post(
          "revision/theory/answer?timetableId=" +
            this.$route.params.id +
            "&questionId=" +
            this.question.id,
          {
            answer: this.question.answer,
          }
        )
        .then(() => {
          this.$set(
            this.questions[questionIndex],
            "saved",
            this.checkAnswerSaved(this.questions[questionIndex])
          );
        })
        .catch(() => {
          this.$set(this.questions[questionIndex], "saved", false);
          alert(
            "Возинкла ошибка, попробуйте ответить на вопрос заново. Если ошибка повторится обратитесь к администратору."
          );
        })
        .finally(() => {
          this.questions[questionIndex].saving = false;
        });
    },
    loadProgress() {
      this.loading = true;
      this.$http
        .post(
          "revision/theory/progress-load?timetableId=" + this.$route.params.id
        )
        .then((response) => {
          this.questions = response.data.questions;
          this.question = this.questions[0];
          let answers = response.data.answers;
          this.currentTime = response.data.timer;
          for (let answer of answers) {
            let questionKey = this.questions.findIndex(
              (el) => el.id === answer.questionId
            );
            if (questionKey !== -1) {
              this.$set(this.questions[questionKey], "answer", answer.answer);
            }
          }
          let pageQuestion = this.questions.find((el) => el.answer == "");
          if (pageQuestion !== undefined) {
            this.question = pageQuestion;
            this.page = this.questions.findIndex(
              (el) => el.id == pageQuestion.id
            );
          }
          for (let question of this.questions) {
            question.saved = this.checkAnswerSaved(question);
          }
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    finish() {
      this.ending = true;
      this.$http
        .get("revision/theory/exam-finish?timetableId=" + this.$route.params.id)
        .then(() => {
          this.stopTimer();
          window.onbeforeunload = function () {};
          this.$router.push("/revision/finish");
        })
        .catch(() => alert("Возникла ошибка"))
        .finally(() => {
          this.ending = false;
        });
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--;
        let hours = Math.floor(this.currentTime / 3600);
        let minutes = Math.floor((this.currentTime - hours * 3600) / 60);
        let seconds = this.currentTime % 60;
        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;
        if (seconds < 10) seconds = "0" + seconds;
        this.countdown = { hours: hours, minutes: minutes, seconds: seconds };
      }, 1000);
      this.pingInterval = setInterval(() => {
        this.ping();
      }, 10000);
    },
    stopTimer() {
      clearInterval(this.pingInterval);
      clearInterval(this.timer);
    },
  },
  data: () => ({
    pingInterval: null,
    ending: false,
    loading: true,
    helpModal: false,
    helpedTypes: [],
    finishModal: false,
    currentTime: 5400,
    timer: null,
    countdown: {
      hours: "00",
      minutes: "00",
      seconds: "00",
    },
    page: 0,
    question: {},
    questions: [],
  }),
  async mounted() {
    window.onbeforeunload = function () {
      return "Данные не сохранены. Точно перейти?";
    };
    await this.loadProgress();

    await this.startTimer();
  },
  beforeDestroy() {
    window.onbeforeunload = function () {};
    this.stopTimer();
  },
};
</script>