<template>
  <div style="max-width: 600px">
    <div v-if="question.type_id === 1">
      <div v-if="question.one">
        <h1 class="py-3">Единичный выбор</h1>
        <v-alert border="top" colored-border type="info" elevation="2">
          При ответе на данный тип вопроса допустим только один верный ответ.
        </v-alert>
        <v-sheet elevation="4">
          <h2 class="pa-1">Пример выполнения</h2>
          <img src="/data/theory/one.gif" class="pa-0" />
        </v-sheet>
      </div>
      <div v-else>
        <h1 class="py-3">Множественный выбор</h1>
        <v-alert border="top" colored-border type="info" elevation="2">
          Допустимы несколько верных ответов. В окне тестирования, по сравнению
          с вопросом "единичный выбор" для выбора ответов вместо круглых
          переключателей используются квадратные флажки
        </v-alert>
        <v-sheet elevation="4">
          <h2 class="pa-1">Пример выполнения</h2>
          <img
            src="/data/theory/several.gif"
            class="pa-0"

          />
        </v-sheet>
      </div>
    </div>
    <div v-if="question.type_id === 2">
      <h1 class="py-3">Сопоставление вариантов</h1>
      <v-alert border="top" colored-border type="info" elevation="2">
        Тип вопроса, похожий на "последовательность вариантов". Отличие
        заключается в том, что здесь присутствует правая часть вариантов
        сопоставления. Для ответа на вопрос необходимо сопоставить варианты
        ответов из левой части с вариантами сопоставления в правой части
      </v-alert>
      <v-sheet elevation="4">
        <h2 class="pa-1">Пример выполнения</h2>
        <img
          src="/data/theory/compliance.gif"
          class="pa-0"

        />
      </v-sheet>
    </div>
    <div v-if="question.type_id === 3">
      <h1 class="py-3">Последовательность вариантов</h1>
      <v-alert border="top" colored-border type="info" elevation="2">
        Тип вопроса, в котором необходимо в верной последовательности расставить
        варианты ответов.
      </v-alert>
      <v-sheet elevation="4">
        <h2 class="pa-1">Пример выполнения</h2>
        <img src="/data/theory/order.gif" class="pa-0" />
      </v-sheet>
    </div>
    <div v-if="question.type_id === 4">
      <h1 class="py-3">Ручной ввод ответа</h1>
      <v-alert border="top" colored-border type="info" elevation="2">
        Для ответа на этот тип вопроса необходимо с клавиатуры ввести ответ в
        виде слова (текста) или числа в отведенное для этого поле.
      </v-alert>
      <v-sheet elevation="4">
        <h2 class="pa-1">Пример выполнения</h2>
        <img src="/data/theory/open.gif" class="pa-0"/>
      </v-sheet>
    </div>
  </div>
</template>
<script>
export default {
  props: ["question"],
};
</script>