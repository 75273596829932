<template>
  <v-row>
    <center v-if="question.img">
      <img :src="$http.defaults.backDir+'/'+question.img" style="border: 1px solid; max-height: 400px" />
    </center>
    <v-col cols="12">
      <v-sheet outlined elevation="3" class="pa-3 ma-3">
        <draggable
          v-bind="dragOptions"
          id="first"
          v-model="rightVariants"
          @start="dragStart"
          @end="dragEnd"
          group="a"
        >
          <v-sheet
            class="ma-1 pa-1"
            style="
              border: 1px solid;
              border-radius: 3px;
              cursor: pointer;
              background: #fafafa;
            "
            v-for="element in rightVariants"
            :key="element.id"
            >{{ element.text }}</v-sheet
          >
        </draggable>
      </v-sheet>

      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="(variant, index) in leftVariants" :key="variant.id">
              <td>
                <h3>{{ variant.text }}</h3>
              </td>
              <td width="50%">
                <v-sheet
                  class="ma-3 pa-3"
                  rounded
                  :color="
                    answers[index] && answers[index][0]
                      ? 'white'
                      : 'grey  lighten-2'
                  "
                  elevation="1"
                  width="100%"
                >
                  <draggable
                    v-bind="dragOptions"
                    @end="dragEnd"
                    style="min-height: 30px"
                    v-model="answers[index]"
                    group="a"
                  >
                    <v-sheet
                      class="ma-1 pa-1"
                      style="
                        border: 1px solid;
                        border-radius: 3px;
                        cursor: pointer;
                        background: #fafafa;
                      "
                      v-for="(answer, aIndex) in answers[index]"
                      :key="aIndex"
                      >{{ answer.text }}</v-sheet
                    >
                  </draggable>
                </v-sheet>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<style >
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
<script>
import draggable from "vuedraggable";
export default {
  name: "order",
  props: ["question"],
  components: {
    draggable,
  },
  data: () => ({
    drag: false,
    dragIndex: null,
    order: [],
    rightVariants: [],
    answers: [],
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    leftVariants() {
      return this.question.variants.filter((el) => el.conf !== 0);
    },
  },
  mounted() {
    this.updateState();
  },
  watch: {
    "question.id"() {
      this.updateState();
    },
  },
  methods: {
    updateState() {
      this.rightVariants = JSON.parse(
        JSON.stringify(this.question.variants.filter((el) => el.conf === 0))
      );
      if (this.question.answer) {
        for (let variantIndex in this.leftVariants) {
          let compliance = this.question.answer.find(
            (el) => el.left === this.leftVariants[variantIndex].id
          );
          this.$set(this.answers, variantIndex, []);
          if (compliance !== -1) {
            for (let rightSide of compliance.right) {
              this.answers[variantIndex].push(
                this.question.variants.find((el) => el.id === rightSide)
              );
              this.rightVariants = this.rightVariants.filter(
                (el) => el.id !== rightSide
              );
            }
          }
        }
      } else {
        for (let variantIndex in this.leftVariants) {
          this.$set(this.answers, variantIndex, []);
        }
      }
    },
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      //this.$set(this.question, "answers", this.answers);

      this.$set(this.question, "answer", []);
      for (let variantIndex in this.leftVariants) {
        if (this.answers[variantIndex]) {
          let answerIds = this.answers[variantIndex].map((el) => el.id);
          this.question.answer.push({
            left: this.leftVariants[variantIndex].id,
            right: answerIds,
          });
        }
      }
      if (this.rightVariants.length === 0) {
        this.$set(this.question, "filled", true);
      } else {
        this.$set(this.question, "filled", false);
      }
      this.$emit("updateAnswer");
      this.drag = false;
    },
  },
};
</script>