<template>
  <div>
    <center v-if="question.img">
      <img :src="$http.defaults.backDir+'/'+question.img" style="border: 1px solid; max-height: 400px" />
    </center>
    <div v-if="question.one !== 1">
      <v-card style="width:100%"
        class="my-2"
        elevation="2"
        shaped
        v-for="variant in question.variants"
        :key="variant.id"
      >
        <v-checkbox
          @change="checkAnswer()"
          multiple
          :label="variant.text"
          v-model="question.answer"
          class="pa-3"
          :value="variant.id"
          hide-details
        ></v-checkbox>
      </v-card>
    </div>
    <v-radio-group v-else v-model="question.answer" @change="checkAnswer()">
      <v-card
        class="my-2"
        elevation="2"
        shaped
        v-for="variant in question.variants"
        :key="variant.id"
      >
        <v-radio
          :label="variant.text"
          :value="variant.id"
          class="pa-3"
        ></v-radio>
      </v-card>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  name: "simple",
  props: ["question"],
  data: () => ({}),
  methods: {
    checkAnswer() {
      this.question.filled = true;
      this.$emit("updateAnswer");
    },
  },
};
</script>