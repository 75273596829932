<template>
  <div>
    <center v-if="question.img">
      <img :src="$http.defaults.backDir+'/'+question.img" style="border: 1px solid; max-height: 200px" />
    </center>
    <v-row class="text-center px-3 pt-3">
      <v-col cols="12" class="py-2">
        <v-card elevation="2" class="pa-3 text-h6" shaped v-if="loaded">
          <span v-for="(part, index) in text" :key="index">
            {{ part
            }}<v-text-field
              @change="checkAnswer()"
              class="mx-3 pa-0"
              :style="
                'width:' +
                fieldsWidth[index] +
                'px;display:inline-block;'
              "
              outlined
              
              dense
              v-model="question.answer[index]"
            /> </span
          >{{ lastPart }}
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "openAnswer",
  props: ["question"],
  components: {},
  data() {
    return {
      loaded: false,
      text: "",
      lastPart: ""
    };
  },
  watch: {
    "question.id"() {
      this.formatText();
    },
  },
  computed:{
    fieldsWidth(){
      let result=[]
    this.text.forEach((el,index) => {
      if(this.question.answer && this.question.answer[index]){
        result.push(40 + 10*this.question.answer[index].length)
      } else {
        result.push(40)
      }
      
    })
    return result
    }
  },
  methods: {
    checkAnswer() {
      this.question.filled = true;
      this.$emit("updateAnswer");
    },
    formatText() {
      this.loaded = false;
      let reg = /\{.*?\}/g;
      this.text = this.question.text.split(reg);
      this.lastPart = this.text.pop();
      if (!this.question.answer) {
        this.$set(this.question, "answer", []);
        this.text.forEach(() => {
          this.question.answer= Array(this.text.length).fill().map(()=>'');
        });
      }
      if(this.question.answer.length!==this.text.length){
        this.question.answer.length=this.text.length
      }
      this.$nextTick(() => {
        this.loaded = true;
      });
    },
  },
  mounted() {
    this.formatText();
  },
};
</script>